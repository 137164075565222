import $ from 'jquery'

$(document).on('turbo:load', function () {
    function switchPaymentTypeByInstitute() {
        if ($('.admin-paymenttype').length == 0) {
            return
        }

        const formerPaymentType = $('.payment-type-container').data('payment-type') || '';
        const instId = $('.admin-paymenttype').find('option:selected').val();
        if (!instId) {
            return
        }
        const selectedPaymentType = $('.admin-paymenttype-shadow').find('[value="' + instId + '"]').data('payment-type');
        const typesHtml = {
            'default': '<div class="field"><div class="control"><label class="nice-form__label nice-form__label--for-radio">Standard</label><input type="hidden" value="default" name="job[payment_type]" id="job_payment_type"></div></div>',
            'selfpaying': '<div class="field"><div class="control"><label class="nice-form__label nice-form__label--for-radio">Selbstzahler</label><input type="hidden" value="selfpaying" name="job[payment_type]" id="job_payment_type"></div></div>',
            'partly_selfpaying': '<div class="field"><div class="control"><label class="nice-form__label nice-form__label--for-radio"><input class="radio" type="radio" value="default" checked="' + (formerPaymentType == 'default' && 'checked') + '" name="job[payment_type]">Standard</label><label class="nice-form__label nice-form__label--for-radio"><input class="radio" type="radio" value="selfpaying"  checked="' + (formerPaymentType == 'selfpaying' && 'checked') + '" name="job[payment_type]">Selbstzahler</label></div></div>'
        }
        selectedPaymentType in typesHtml && $ptc.html(typesHtml[selectedPaymentType]);
    }

    const $ptc = $('.payment-type-container');

    $('.payment-type-container').html('<div class="field"><div class="control"><label class="nice-form__label nice-form__label--for-radio">-</label>');

    switchPaymentTypeByInstitute();
    $('.admin-paymenttype').on('change', switchPaymentTypeByInstitute);

    function switchTravelCostTypeByLanguage() {
        const langId = $('.admin-travelcost').find('option:selected').val();
        let selectedTravelCostValue = 0;
        if (langId) {
            selectedTravelCostValue =
                $('.admin-travelcost-shadow')
                    .find('[value="' + langId + '"]')
                    .data('travelcost');
        }
        $('[name="job[travel_cost]"],[data-travel-cost-field]').val(selectedTravelCostValue);
        if (parseInt(selectedTravelCostValue) > 0) {
            $('input#job_travel_cost_reason_by_language').prop('checked', true);
        } else {
            $('input#job_travel_cost_reason_none').prop('checked', true);
        }
    }

    $('.admin-travelcost').on('change', switchTravelCostTypeByLanguage);

    return
});
