import $ from 'jquery'

function toggleSearchBoxOpacity() {
    if ($("input[type='search']").length) {
        $(".search-box").toggleClass("is-opaque",
            $("input[type='search']").val().length > 0);
    }
}

function attachFilterRowHandler() {
    console.log('attaching')
    $(".filter-row__select,[data-submit-on-change]").on('change', function (e) {
        toggleSearchBoxOpacity();
        $(this).parents("form").get(0).requestSubmit();
    });

    $("[data-form-reset]").on("click", function (e) {
        e.preventDefault();
        $(this).parents().find("input[type='search']").val("");
        toggleSearchBoxOpacity();
        $(this).parents("form").get(0).requestSubmit();
    })
}


$(document).on('turbo:render', attachFilterRowHandler);
$(document).on('turbo:render', toggleSearchBoxOpacity);
$(document).on('turbo:load', toggleSearchBoxOpacity);
$(document).on('turbo:load', attachFilterRowHandler);
$(document).on('turbo:frame-load', attachFilterRowHandler);
