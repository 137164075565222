import $ from 'jquery'
$(document).on('turbo:load', function () {
    $('.content-edit').dblclick(function (e){
        var id = $(this).data("content-id");
        var backUrl = $(this).data("back-url")

        var targetUrl = "/admin/content_items/"+ id  +"/edit";
        if (backUrl){
            targetUrl += "?back_url="+encodeURIComponent(backUrl)
        }
        window.location.href = targetUrl
    });

});