import $ from "jquery";

$(document).on('turbo:load', function () {
    if (!$(".index-table").length) {
        return;
    }

    // first row has labels
    $(".index-table").each(function (itIndex) {
        let $that = $(this);
        $that.find("th").each(function (thIndex) {
            const thelabel = $(this).text().trim();
            $that.find('tr').each(function () {
                $(this).find('td').eq(thIndex).attr('data-thelabel', thelabel);
            });
        });

    });
})