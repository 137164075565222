import $ from 'jquery'

$(document).on('turbo:load', function () {
    let scrollTimeOut;

    function showMobileNavigation() {
        $(".mobile-navigation__content").addClass("is_open");
        $(".mobile-navigation__cover").addClass("is_open");
        preventBodyScrolling();
        $('.mobile-navigation__list-item.has-subnavigation.is-open').removeClass("is-open");
        $('.mobile-navigation__list-item.has-subnavigation.selected').addClass("is-open");
    }

    function hideMobileNavigation() {
        $(".mobile-navigation__content").removeClass("is_open");
        $(".mobile-navigation__cover").removeClass("is_open");
        enableBodyScrolling();
    }

    function preventBodyScrolling() {
        $("html").css("overflow-y", "hidden");
        $(window).on('resize', checkScrollLock);
    }

    function checkScrollLock() {
        if ($(window).width() >= 1088) {
            console.log("enabled again, disabled handler");
            enableBodyScrolling();
            $(window).unbind('resize', checkScrollLock);
        }
    }

    function enableBodyScrolling() {
        $("html").css("overflow-y", "scroll");
        $(window).unbind('resize', checkScrollLock);
    }

    $('.mobile-navigation__list-item.has-subnavigation').click(function (e) {
        if ($(e.target).hasClass('has-subnavigation')) {
            e.preventDefault();
        }
        $('.mobile-navigation__list-item.has-subnavigation.is-open').removeClass("is-open");
        $(this).addClass("is-open");
    });

    $(".mobile-navigation__opener").click(showMobileNavigation);
    $(".mobile-navigation__close").click(hideMobileNavigation);
    $(".mobile-navigation__cover").click(hideMobileNavigation);
    enableBodyScrolling();
});







