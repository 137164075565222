import "./application.scss";


import "@fontsource/niramit/";
import "@fontsource/niramit/400-italic.css";
import "@fontsource/niramit/700.css";
import "@fontsource/niramit/700-italic.css";
import "chartkick/chart.js"
//import "jquery";
import * as Turbo from '@hotwired/turbo'
import "trix"
import "@rails/actiontext"
import "../components/content_edit"
import "../components/filter-row"
import "../components/init_select2"

import "../components/mobileNavigation"
import "../components/pdf_links"
import "../components/mobileTables"
import "../components/richtext"
import "../components/job_edit"


Turbo.start()
/*

*/

