import "choices.js/src/styles/choices.scss";
import $ from 'jquery'
import Choices from "choices.js/src/scripts/choices";

$(document).on('turbo:load', function () {
    const selects = $('.select2')
    if (selects.length) {
        selects.each(function (el) {
            const choices = new Choices(this, {
                noResultsText: 'Leider kein passender Eintrag gefunden.',
                searchResultLimit: 500,
            })
        })
    }
});